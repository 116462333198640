import React from 'react';

import { type User, useUser } from '../authentication';
import {
	and,
	isDriveTenant,
	isInvestor,
	isInvestorRelationsPartner,
	isSeedManager,
	isStaff,
	or,
} from '../components/Authorized';

import DeprecatedView from './deprecated-view';
import BetaView from './view';

export function showUserBetaView(user: User) {
	return or(
		isStaff,
		and(
			isDriveTenant,
			or(isInvestor, isInvestorRelationsPartner, isSeedManager),
		),
	)(user);
}

export default function View() {
	const user = useUser();

	if (showUserBetaView(user)) {
		return <BetaView />;
	}
	return <DeprecatedView />;
}
