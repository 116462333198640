/**
 * @file
 * Shapes & Symbols icons from [Figma][1].
 *
 * [1]: https://www.figma.com/design/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=583-43&m=dev
 */
import React from 'react';

export function CircleFillIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="12" cy="12" r="10" fill="currentColor" />
		</svg>
	);
}

export function LoaderIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 20 20"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M9 0H11V5H9V0Z" />
			<path d="M16.364 2.2218L17.7782 3.63602L14.2426 7.17155L12.8284 5.75734L16.364 2.2218Z" />
			<path d="M20 9V11H15V9H20Z" />
			<path d="M17.7782 16.364L16.364 17.7782L12.8284 14.2427L14.2426 12.8285L17.7782 16.364Z" />
			<path d="M9 15H11V20H9V15Z" />
			<path d="M5.75736 12.8284L7.17158 14.2426L3.63604 17.7781L2.22183 16.3639L5.75736 12.8284Z" />
			<path d="M5 9V11H0L8.74228e-08 9H5Z" />
			<path d="M7.17157 5.75732L5.75736 7.17154L2.22182 3.636L3.63604 2.22179L7.17157 5.75732Z" />
		</svg>
	);
}
