import { breakpoints } from '@drivecapital/design-system';
import { EmptyStatePlaceholder } from '@drivecapital/design-system/components';
import listify from '@drivecapital/listify';
import { uniq } from 'lodash';
import partition from 'lodash.partition';
import React, { useCallback, useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import styled from 'styled-components';

import { useUser } from '../authentication';
import Header from '../components/header';
import { trackEvent } from '../utils/analytics';
import assertExhaustive from '../utils/assert-exhaustive';
import useAnalytics from '../utils/hooks/use-analytics';
import { useLocalStorage } from '../utils/hooks/use-browser-storage';
import useSearchParam from '../utils/hooks/use-search-param';

import { useTravelUsers } from './api';
import PickerHeader from './picker-header';
import RecommendationsCard from './recommendations-card';
import type { TravelRecommendationStrategy, TravelUser } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
const Main = styled.main`
	display: grid;
	grid-template:
		'picker-header' auto
		/ 1fr;

	overflow-y: scroll;
	padding: 12px;
	row-gap: 6px;

	@media (${breakpoints.md}) {
		padding: 24px;
		row-gap: 12px;
	}
`;

const Recommendations = styled.div`
	column-gap: 16px;
	display: grid;
	grid-auto-rows: auto;
	row-gap: 26px;

	grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
	@media (${breakpoints.sm}) {
		grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
	}
`;

const INVESTOR_STRATEGIES = [
	'MARKET_MAP_GROWTH_COMPANIES',
	'HIGH_BEAM_PIPELINE',
	'SIMILAR_COMPANIES',
	'PREVIOUSLY_MET_COMPANIES',
	'OUTREACH_COMPANIES',
] as const;

const LP_STRATEGIES = ['LP_COMPANIES'] as const;

const ALL_STRATEGIES = [...INVESTOR_STRATEGIES, ...LP_STRATEGIES];

function buildRecommendationStrategies(
	personas: TravelUser['personas'],
): Array<TravelRecommendationStrategy> {
	return uniq(
		personas.flatMap((persona) => {
			switch (persona) {
				case 'F4_GENERAL_MANAGER':
					return INVESTOR_STRATEGIES;
				case 'GENERAL_PARTNER':
					return [...INVESTOR_STRATEGIES, ...LP_STRATEGIES];
				case 'INVESTOR':
					return INVESTOR_STRATEGIES;
				case 'INVESTOR_RELATIONS':
					return LP_STRATEGIES;
				case 'STAFF':
					return ALL_STRATEGIES;
				case 'TALENT':
					return [];
				default:
					return assertExhaustive(persona);
			}
		}),
	);
}

function useRecommendationStrategies(selectedUserId: number | null) {
	const { data: users, isLoading } = useTravelUsers();

	const selectedTravelUser = useMemo(() => {
		return (users ?? []).find((user) => user.id === selectedUserId);
	}, [selectedUserId, users]);

	return {
		isLoading,
		recommendationStrategies: useMemo<TravelRecommendationStrategy[]>(
			() =>
				buildRecommendationStrategies(
					selectedTravelUser?.personas ?? [],
				),
			[selectedTravelUser],
		),
		travelUser: selectedTravelUser,
	};
}

const RECOMMENDATIONS_PAGE_SIZE = 5;
const initialHiddenStrategies: TravelRecommendationStrategy[] = [];

export default function View() {
	const analyticsInfo = useMemo<Parameters<typeof useAnalytics>[0]>(
		() => ({
			componentIdentifier: 'travel-view',
			eventName: 'Visit New Page',
			properties: { version: 'v2' },
			viewType: 'travel',
		}),
		[],
	);
	useAnalytics(analyticsInfo);

	const authenticatedUser = useUser();
	const [location, setLocation] = useSearchParam('location', '');
	const [selectedUserParam, setSelectedUserParam] = useSearchParam(
		'userId',
		authenticatedUser.id.toString(),
	);
	const selectedUserId = useMemo(
		() =>
			selectedUserParam != null && selectedUserParam !== ''
				? parseInt(selectedUserParam, 10)
				: null,
		[selectedUserParam],
	);

	const handleLocationSelect = useCallback(
		(newLocation: string | null) => {
			setLocation(newLocation ?? '');
		},
		[setLocation],
	);
	const handleUserSelect = useCallback(
		(newUserId: number | null) => {
			if (newUserId == null) {
				setSelectedUserParam('');
			} else {
				setSelectedUserParam(newUserId.toString());
			}
		},
		[setSelectedUserParam],
	);
	const {
		recommendationStrategies,
		isLoading: recommendationStrategiesLoading,
		travelUser,
	} = useRecommendationStrategies(selectedUserId);
	const [hiddenStrategyNames, setHiddenStrategies] = useLocalStorage(
		`travel-recommendations-hidden-strategies-${selectedUserId ?? 'unknown'}`,
		initialHiddenStrategies,
	);
	const handleStrategyVisibilityToggle = useCallback(
		(strategy: TravelRecommendationStrategy) => {
			trackEvent(
				'Toggle Travel Recommendation Strategy',
				'view',
				'travel',
				{ recommendationStrategy: strategy },
			);
			setHiddenStrategies((prev) => {
				if (prev.includes(strategy)) {
					return prev.filter((s) => s !== strategy);
				}
				return [...prev, strategy];
			});
		},
		[setHiddenStrategies],
	);
	const [visibleStrategies, hiddenStrategies] = useMemo(
		() =>
			partition(
				recommendationStrategies,
				(strategy) => !hiddenStrategyNames.includes(strategy),
			),
		[hiddenStrategyNames, recommendationStrategies],
	);

	const showRecommendations =
		recommendationStrategies.length > 0
		&& selectedUserId != null
		&& travelUser != null
		&& location;
	const showNoRecommendations =
		recommendationStrategies.length === 0
		&& !recommendationStrategiesLoading
		&& selectedUserId != null
		&& location;
	const showEmptyState =
		!showRecommendations
		&& !showNoRecommendations
		&& !recommendationStrategiesLoading;

	const missingInputs = [];
	if (!location) {
		missingInputs.push('location');
	}
	if (selectedUserId == null) {
		missingInputs.push('traveler');
	}

	return (
		<DocumentTitle title="Travel">
			<Container>
				<Header search />
				<Main>
					<PickerHeader
						authenticatedUser={authenticatedUser}
						defaultLocation={location ?? ''}
						onLocationSelect={handleLocationSelect}
						onUserSelect={handleUserSelect}
						selectedUserId={selectedUserId}
					/>
					{showRecommendations && (
						<Recommendations>
							{visibleStrategies.map((strategy) => (
								<RecommendationsCard
									authUser={authenticatedUser}
									hidden={false}
									key={strategy}
									location={location}
									onStrategyToggle={
										handleStrategyVisibilityToggle
									}
									pageSize={RECOMMENDATIONS_PAGE_SIZE}
									strategy={strategy}
									travelUser={travelUser}
								/>
							))}
							{hiddenStrategies.map((strategy) => (
								<RecommendationsCard
									authUser={authenticatedUser}
									hidden
									key={strategy}
									location={location}
									onStrategyToggle={
										handleStrategyVisibilityToggle
									}
									pageSize={RECOMMENDATIONS_PAGE_SIZE}
									strategy={strategy}
									travelUser={travelUser}
								/>
							))}
						</Recommendations>
					)}

					{showNoRecommendations && (
						<EmptyStatePlaceholder>
							No recommendations available for this user
						</EmptyStatePlaceholder>
					)}
					{showEmptyState && (
						<EmptyStatePlaceholder>
							Select a {listify(missingInputs)} to see
							recommendations
						</EmptyStatePlaceholder>
					)}
				</Main>
			</Container>
		</DocumentTitle>
	);
}
