import {
	breakpoints,
	colors,
	effects,
	fonts,
} from '@drivecapital/design-system';
import { EnvelopeIcon } from '@drivecapital/design-system/icons/document';
import { DollarSignCircleOutlineIcon } from '@drivecapital/design-system/icons/finance';
import { PersonIcon } from '@drivecapital/design-system/icons/human';
import { CircleFillIcon } from '@drivecapital/design-system/icons/shapes-symbols';
import { SearchIcon } from '@drivecapital/design-system/icons/system';
import { USAFillIcon } from '@drivecapital/design-system/icons/travel';
import numeral from 'numeral';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
	LatestEmailBody,
	LatestEmailHeader,
} from '../company-recommendation/latest-email';
import {
	LatestEventBody,
	LatestEventHeader,
} from '../company-recommendation/latest-event';
import {
	LatestNoteHeader,
	LatestNoteBody as UnstyledLatestNoteBody,
} from '../company-recommendation/latest-note';
import type { TravelLPCompanyRecommendation } from '../types';

const Card = styled.div`
	${effects.shadow.elevated};
	background-color: ${colors.layer.layer};
	border-radius: 12px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 10px 8px;
	width: 100%;

	@media (${breakpoints.sm}) {
		height: 250px;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	/* fonts.h4.productive.line-height */
	height: 28px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Logo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	grid-area: logo;
	height: 24px;
	object-fit: cover;
	width: 24px;
`;

const Name = styled(Link)`
	${fonts.h4.productive};
	color: ${colors.text.interactive};
	grid-area: name;

	&:hover {
		color: ${colors.text.interactiveHover};
		cursor: pointer;
	}
`;

const CardBody = styled.div`
	display: grid;
	gap: 0px;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	grid-tempalte-columns: 1fr;
	@media (${breakpoints.sm}) {
		grid-template-columns: 1fr 1fr;
	}
`;
const CardBodyLeft = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 8px;

	border-bottom: 1px solid ${colors.border.subtle};
	border-right: none;
	padding-bottom: 8px;
	padding-right: 8px;
	@media (${breakpoints.sm}) {
		border-bottom: none;
		border-right: 1px solid ${colors.border.subtle};
		padding-bottom: 0px;
	}
`;

const CardBodyLeftRow = styled.div`
	align-items: flex-start;
	display: flex;
	max-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;

	gap: 4px;

	& > div {
		align-items: flex-start;
		display: flex;

		& > svg {
			height: 18px;
			width: 18px;
		}
	}

	& > span {
		${fonts.paragraph.paragraph};
		color: ${colors.text.primary};

		&:first-of-type {
			${fonts.paragraph.strong};
		}
	}
`;
const ColoredUSAFillIcon = styled(USAFillIcon)`
	color: ${colors.icon.interactive};
`;

const CardBodyRight = styled.div`
	padding: 0px 8px 8px 8px;
`;
const LatestNoteBody = styled(UnstyledLatestNoteBody)`
	max-height: 160px;
`;

interface Props {
	readonly lpCompany: TravelLPCompanyRecommendation;
}

export default function LPCompanyRecommendation({ lpCompany }: Props) {
	let interaction: React.ReactNode = null;
	let interactionHeader: React.ReactNode = null;

	if (lpCompany.latest_event) {
		interactionHeader = (
			<LatestEventHeader event={lpCompany.latest_event} />
		);
		interaction = <LatestEventBody event={lpCompany.latest_event} />;
	} else if (
		lpCompany.latest_emails.received.length > 0
		|| lpCompany.latest_emails.sent.length > 0
	) {
		interactionHeader = (
			<LatestEmailHeader emails={lpCompany.latest_emails} />
		);
		interaction = <LatestEmailBody emails={lpCompany.latest_emails} />;
	} else if (lpCompany.latest_note) {
		interactionHeader = <LatestNoteHeader note={lpCompany.latest_note} />;
		interaction = <LatestNoteBody note={lpCompany.latest_note} />;
	}

	return (
		<Card>
			<Header>
				<Logo src={lpCompany.logo_url} alt={`${lpCompany.name} logo`} />
				<Name to={`/companies/${lpCompany.fused_company_id}`}>
					{lpCompany.name}
				</Name>
			</Header>
			<CardBody>
				<CardBodyLeft>
					<CardBodyLeftRow>
						<div>
							<DollarSignCircleOutlineIcon />
						</div>
						<span>Drive '25$:</span>
						<span>
							{numeral(lpCompany.full_commitment || 0).format(
								'$0.0a',
							)}
						</span>
					</CardBodyLeftRow>
					<CardBodyLeftRow>
						<div>
							<SearchIcon />
						</div>
						<span>Probability:</span>
						<span>{lpCompany.probability_future}</span>
					</CardBodyLeftRow>
					<CardBodyLeftRow>
						<div>
							<ColoredUSAFillIcon />
						</div>
						<span>Region:</span>
						<span>{lpCompany.region}</span>
					</CardBodyLeftRow>
					<CardBodyLeftRow>
						<div>
							<CircleFillIcon />
						</div>
						<span>Type:</span>
						<span>{lpCompany.lp_type}</span>
					</CardBodyLeftRow>
					{lpCompany.drive_point_of_contact && (
						<CardBodyLeftRow>
							<div>
								<PersonIcon />
							</div>
							<span>PoC:</span>
							<span>
								{lpCompany.drive_point_of_contact.first_name}
							</span>
						</CardBodyLeftRow>
					)}
					<CardBodyLeftRow>
						<div>
							<EnvelopeIcon />
						</div>
						<span>Status:</span>
						<span>{lpCompany.next_step}</span>
					</CardBodyLeftRow>
				</CardBodyLeft>
				<CardBodyRight>
					{interactionHeader}
					{interaction}
				</CardBodyRight>
			</CardBody>
		</Card>
	);
}
